import React from 'react'

// Component to display the Instagram feed for the farm
export const FarmFeed = (props) => {
  return (
    <div id="farm-feed">
      <h1 className="title">Farm Feed</h1>
      <div className="content">
      </div>
    </div>
  )
}

export default FarmFeed